var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "支付宝子商户扫码授权" },
      on: { ok: _vm.handleOkFunc, cancel: _vm.handleOkFunc },
      model: {
        value: _vm.isShow,
        callback: function($$v) {
          _vm.isShow = $$v
        },
        expression: "isShow"
      }
    },
    [
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _c("p", [
          _vm._v("方式1： "),
          _c("br"),
          _vm._v(
            " 使用商家账号登录【支付宝】APP, 扫描如下二维码, 按提示授权： "
          )
        ]),
        _c("img", {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { src: _vm.apiResData.authQrImgUrl }
        }),
        _c("hr"),
        _c(
          "p",
          { staticStyle: { "margin-top": "10px" } },
          [
            _vm._v(" 方式2： "),
            _c("br"),
            _vm._v(" "),
            _c(
              "a-button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.apiResData.authUrl,
                    expression: "apiResData.authUrl",
                    arg: "copy"
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onCopySuccess,
                    expression: "onCopySuccess",
                    arg: "success"
                  }
                ],
                staticClass: "copy-btn",
                attrs: { size: "small" }
              },
              [_vm._v("点击复制")]
            ),
            _vm._v(" 或点击以下链接，按照页面提示自主授权： ")
          ],
          1
        ),
        _c("a", { attrs: { target: "_blank", href: _vm.apiResData.authUrl } }, [
          _vm._v(_vm._s(_vm.apiResData.authUrl))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }